* {
  font-family: 'Roboto','Francois One', sans-serif;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
body {
  padding: 0;
  margin: 0;
}
h1, h2, h3 {
  color: #272727;
  font-family: 'Roboto','Francois One', sans-serif;
  font-weight: 500;
  margin: 13px 0;
}
h2 {
  line-height: 30px;
  margin: 13px 0;
}
h3 {
  font-size: 16px;
}
a, p, span {
  color: #272727;
  font-family: 'Roboto','Crimson Text','Domine', serif;
  font-size: 13px;
  line-height: 20px;
  font-weight: 400;
}
/*.content {
  background-image: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path d="M9.4 16.6L4.8 12l4.6-4.6L8 6l-6 6 6 6 1.4-1.4zm5.2 0l4.6-4.6-4.6-4.6L16 6l6 6-6 6-1.4-1.4z"/></svg>');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-color: #efefef;
}*/
/* Layout */
.flex-container {
  display: flex;
}
.nav,
.content {
  flex-direction: column;
  float: none !important;
  height: 100vh !important;
}
.nav {
  width: auto !important;
  flex: 1;
}
.content {
  width: 100vh !important;
  flex: 3;
}


li, li span {
  font-family: 'Roboto','Francois One', sans-serif;
  font-weight: normal;
}
p {
  margin-bottom: 13px;
}
.MuiDivider-root {
  margin-bottom: 20px;
}
.mid-content .MuiDivider-root {
  background-color: transparent;
  clear:both;
}
.MuiListItemIcon-root {
  display: inline-flex;
  min-width: 35px;
  flex-shrink: 0;
}
.MuiListItemText-root {
  flex: 1 1 auto;
  margin-top: 4px;
  margin-bottom: 4px;
}
.container {
  display: flex;
  flex-direction: row;
}
.flex-main {
  max-width: 70%;
  padding-right: 40px;
}
.flex-main p {
  font-size: 18px;
  line-height: 32px;
}
.nav {
  background-color: #3569b8;
  -webkit-box-shadow:3px 1px 10px rgba(0,0,0,0.6), inset 0px 0px 20px rgba(0,0,0,0.1);
  -moz-box-shadow:3px 1px 10px rgba(0,0,0,0.6), inset 0px 0px 20px rgba(0,0,0,0.1);
  box-shadow:1px 1px 10px rgba(0,0,0,0.6), inset 0px 0px 20px rgba(0,0,0,0.1);
  width: 30%;
  max-width: 260px;
  float: left;
  position: relative;
  height: 120vh;
  padding: 0 20px;
  z-index: 99;
  -webkit-transition: all .3s cubic-bezier(.42, 0, 1, 1);
	-moz-transition: all .3s cubic-bezier(.42, 0, 1, 1);
  transition: all .3s cubic-bezier(.42, 0, 1, 1);
}
.nav h1 {
  color: #fff;
  margin-right: 10px;
  margin-left: 0px;
  margin-bottom: 35px;
}
.nav .role {
  color: #fff;
  display: block;
  font-family: 'Roboto','IBM Plex Sans','Domine', serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 15px;
}
.nav .menuBtn {
  display: none;
  background-color: #fff;
  cursor: pointer;
  position: absolute;
  padding: 5px 10px;
  padding-bottom: 0;
  top: 20px;
  right: 20px;
  -webkit-transition: all ease 0.3s;
	-moz-transition: all ease 0.3s;
  transition: all ease 0.3s;
}
.nav .menuBtn svg {
  color: #000;
  font-size: 30px;
}
.nav .menuBtn:hover {
  background-color: #000;
}
.nav .menuBtn:hover svg{
  color: #fff;
}
ul.header {
  background-color: #3569b8;
  margin-top:30px;
  padding: 0;
  width: 100%;
  border-top: 1px solid #fff;
  padding-top: 20px;
}
ul.header li {
  background-color: #fff;
  display: block;
  list-style-type: none;
  margin: 0 0 0 0;
  width: 100%;
}
ul.header li {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 5px;
}
ul.header li a{
  color: #000;
  text-decoration: none;
  padding: 5px 0 5px 10px;
  display: inline-flex;
  width: 100%;
  -webkit-transition: all ease 0.2s;
	-moz-transition: all ease 0.2s;
  transition: all ease 0.2s;
}
ul.header li a:hover {
  text-decoration: none;
  background-color: #272727;
  color: #fff;
}
ul.header li a.active {
  background-color: #272727;
  color: #fff;
}
ul.header li a.active .MuiListItemIcon-root{
  color: #fff;
}

ul.header li a .MuiListItemText-root{
  font-family: 'Roboto','Francois One', sans-serif;
  font-size: 15px; 
}
ul.header li a:hover .MuiListItemText-root,
ul.header li a:hover .MuiListItemIcon-root,
ul.header li a.active .MuiListItemText-root { color: #fff;}
.nav h3 {
  border-top: 1px solid #fff;
  color: #fff;
  margin: 15px 0 0 0;
  padding: 20px 0 0 0;
}
ul.socials {
  margin-top: 10px;
  padding:  0 0 0 20px;
}
ul.socials li {
  color: #fff;
  list-style-type: square;
  margin: 0 0 5px 0;
}
ul.socials li a {
  color: #fff;
  font-family: 'Roboto','Francois One', sans-serif;
  font-size: 13px;
  text-decoration: none;
}
ul.socials li a:hover {
  text-decoration: underline;
}
.content {
  background-color: #fff;
  float: left;
  padding: 0;
  width: 82%;
  height: 100vh;
}
.content li {
  margin-bottom: 10px;
}
.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.mid-content {
  background-color: #efefef;
  color: #000;
  min-width: 220px;
  max-width: 325px;
  width: 30%;
  height: 100vh;
  float:left;
  padding: 20px 20px 0 20px;
  -webkit-box-shadow:inset -10px 1px 10px -10px #999;
  -moz-box-shadow:inset -10px 1px 10px -10px #999;
  box-shadow:inset -10px 1px 10px -10px #999;
}
.mid-content ul {
  padding-left: 0;
}
.mid-content ul li {
  list-style-type: none;
}
.main-content {
  float: left;
  width: 70%;
  padding-left: 20px;
  padding-right: 20px;
}
.main-content img {
  width: 100%;
}
.main-content ul {
  padding-left: 15px;
}
/* Home */
.home {
  background-image: url('/images/homebg.png');
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100vh;
}
.home-container {
  border: 10px solid #272727;
  padding: 20px 10px;
  background-color: #fff;
}
.home h2,
.home p {
  text-align:center;
}
.home h2 {
  color: #3569b8;
  font-size: 45px;
  line-height: 50px;
}
.home p {
  font-size: 23px;
  line-height: 38px;
  max-width: 400px;
}
.home a,
.home a:visited {
  background-color: #efefef;
  color:#272727;
  font-size: 23px;
  text-decoration: none;
  padding: 3px 5px;
}
.home a:hover {
  background-color: #272727;
  color:#fff;
  text-decoration: none;
}
.home strong {
  font-weight: normal;
}
.home .creative {
  color: #3569b8;
}

/* About */
.mid-content .download {
  display: block;
  padding: 10px 12px;
  background-color: #fff;
  color: #272727;
  margin-bottom: 30px;
  text-decoration: none
}
.mid-content .download:hover {
  background-color: #272727;
  color: #fff;
  text-decoration: none;
}
.mid-content .download svg {
  float: left;
  margin-right: 15px;
}
.mid-content.toolkit {
  overflow: hidden;
  position: relative;
}
.mid-content.toolkit h2 {
  border-bottom: 2px solid #272727;
  padding-bottom: 10px;
}
.mid-content.toolkit ul {
  padding-left: 20px;
  margin-top: 22px;
  margin-bottom: 20px;
}
.mid-content.toolkit ul li {
  color: #3569b8;
  list-style-type: square;
}
.mid-content.toolkit ul li span {
  color: #272727;
}
.mid-content h3.edu span {
  font-family: "Roboto", arial, sans-serif;
  font-weight: normal;
}
.main-content.about {
  height: 100vh;
  overflow-y: auto;
}
.main-content.about h1 {
  border-bottom: 2px solid #272727;
  padding-bottom: 10px;
  margin-bottom: 20px;
  margin-top: 22px;
}
.main-content.about p {
  font-size: 18px;
  line-height:32px;
}
.main-content.about img {
  border: 10px solid #efefef;
  float: right;
  margin-left: 40px;
  margin-bottom: 40px;
  width: 30%;
  min-width: 200px;
  -webkit-box-shadow: -4px 7px 6px -5px #777;
  -moz-box-shadow: -4px 7px 6px -5px #777;
  box-shadow: -4px 7px 6px -5px #777;
}


/* Interactive */
.mid-content.work {
  color: #000;
  overflow: hidden;
  position: relative;
  -webkit-box-shadow:inset -10px 1px 10px -10px #999;
  -moz-box-shadow:inset -10px 1px 10px -10px #999;
  box-shadow:inset -10px 1px 10px -10px #999;
}
.mid-content .thumb-container {
  overflow: hidden;
  height: 100vh;
  position: relative;
}
.controls {
  float:right;
  width: auto;
  margin-top: 10px;
}
.scroll-btn {
  background-color: #fff;
  cursor: pointer;
  float: left;
  width: 30px;
  height: 30px;
  margin-left: 5px;
}

.scroll-btn .MuiSvgIcon-root {
  color: #000;
  height: 30px;
  width: 30px;
  /*-webkit-filter: drop-shadow(3px 3px 3px rgba(0,0,0,0.5));      
  filter: drop-shadow(3px 3px 3px rgba(0,0,0,0.5)); */ 
}
.scroll-btn:hover {
  background-color: #000;
}
.scroll-btn:hover .MuiSvgIcon-root {
  color: #fff;
}
.thumbs {
  position: absolute;
  top: 0;
  transition: all .3s cubic-bezier(.42, 0, 1, 1);
}
.mid-content.work h2 {
  font-size: 24px;
  border-bottom: 2px solid #272727;
  padding-bottom: 10px;
}
.main-content.work {
  height: 100vh;
  overflow-y: auto;
}
.main-content.work h1 {
  border-bottom: 2px solid #272727;
  margin-top: 22px;
  padding-bottom: 10px;
  width: 99%;
}
.mid-content.work img {
  margin-bottom: 20px;
  width: 100%;
}
.mid-content.work .project-card {
  background-color: #fff;
  -webkit-box-shadow: 0 10px 6px -6px #777;
  -moz-box-shadow: 0 10px 6px -6px #777;
  box-shadow: 0 10px 6px -6px #777;
  position:relative;
}
.mid-content.work .project-card img {
  display: block;
  margin: 0 auto;
  width: 99.9%;
}
.mid-content.work a:visited {
  color: #fff;
  text-decoration: none;
}
.mid-content.work a,
.mid-content.work a h3,
.mid-content.work a:hover,
.mid-content.work a:visited h3 {
  text-decoration: none;
}
.mid-content.work a {
  display: block;
  margin: 10px 0;
}
.mid-content.work a.active h3{
  background-color: #272727;
  color: #fff;
  display: inline-block;
}
.mid-content.work a p{margin:0;}
.mid-content.work a h3 {
  color: #272727;
  display: none;
  font-size: 18px;
  margin-bottom: 0;
  margin-top: 0;
  margin-left: 0;
  padding-top: 20px;
  padding-bottom: 10px;
  width: 100%;
  padding-left: 10px;
  border-left: 20px solid #3569b8;
  -webkit-transition: all ease 0.2s;
	-moz-transition: all ease 0.2s;
  transition: all ease 0.2s;
  height: 100%;
}
.mid-content.work a:hover h3 {
  background-color: #272727;
  color: #fff;
  display: inline-block;
  
}
.mid-content.work a h3 {
  position: absolute;
  z-index: 99;
}
.mid-content.work a p {
  position: relative;
  z-index: 1;
}
ul.tech {
  padding-left: 20px;
}
ul.tech li {
  color: #3569b8;
  list-style-type: square;
  margin-bottom: 5px;
}
ul.tech li span {
  color: #272727;
  font-size: 13px;
  vertical-align: top;
}
.flex-side {
  background-color: #efefef;
  width: 29%;
  margin-top: 10px;
  padding-left: 20px;
  padding-bottom: 20px;
}
.flex-side h3 {
  color: #3569b8;
}

/*Slider*/
.slider {
  border:10px solid #efefef;
  height: 50vh;
  width: 99%;
  overflow: hidden;
  position: relative;
  margin-top: 25px;
  margin-bottom: 40px;
  -webkit-box-shadow: 0 10px 6px -6px #777;
  -moz-box-shadow: 0 10px 6px -6px #777;
  box-shadow: 0 10px 6px -6px #777;
}

.slider .slide {
  min-height: 50vh;
}
.slide-arrow {
  border: 1px solid #000;
  background-color: #000;
  color: #fff;
  cursor: pointer;
  display: block;
  height: auto;
  position: absolute;
  top: 40%;
}
.slide-arrow .MuiSvgIcon-root {
  height: 1.5em;
  width: 1.5em;
}
.slide-arrow.left {
  left: 0;
}
.slide-arrow.right {
  right: 0;
}

.int-flex,
.about-flex {
    display: flex;
  }
  .mid-content.work {
    flex: 1;
  }
  .mid-content.toolkit {
    flex: 1;
    overflow-y: auto;
  }
  .main-content.work,
  .main-content.about {
    flex: 3;
    overflow-y: auto;
  }

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@media only screen { 

} /* Define mobile styles */

@media only screen and (max-width: 40em) { 
  .nav {
    width: 100% !important;
    flex: 0 1 auto;
    max-width: none;
    height: 100px !important;
    overflow:hidden;
  }
  .nav.show {
    height: 450px !important;
  }
  .nav .menuBtn {
    display: block;
  }


  .flex-container {
    display: block;
  }
  .content {
    flex-direction: row;
    float: left !important;
    height: auto !important;
    width: 100% !important;
    flex: 0 1 auto;
  }
  .home {
    padding: 0 20px;
  }
  .mid-content,
  .mid-content.work {
    width: 100%;
    max-width: none;
    height: auto;
  }

  .mid-content .thumb-container {
    height: 275px;
    position: relative;
    overflow: hidden;
    margin: 0 auto;
  }
  
  .thumbs {
    margin: 0px auto; left: 0px; width: 280px; position: relative;
  }
  .main-content,
  .main-content.work {
    width: 100%;
    padding-right: 20px;
    overflow: visible;
  }
  .main-content.work .container {
    display: block;
  }
  .flex-main {
    width: 100%;
    max-width: none;
    float: left;
  }
  .flex-side {
    float: left;
    clear: both;
    width: 100%;
    margin-bottom: 30px;
  }
  .main-content.about img {
    float: none;
    display: block;
    text-align:center;
    margin:20px auto;
    min-width: 200px;
  }
  .int-flex,
  .about-flex {
    display: block;
  }
  .mid-content.toolkit {
    flex: 0;
    overflow-y: hidden;
  }
  .main-content.work, .main-content.about {
    flex: auto;
    height: auto;
  }
  
} /* max-width 640px, mobile-only styles, use when QAing mobile issues */


@media only screen and (min-width: 40.063em) { 
  .content {

  }
} /* min-width 641px, medium screens */

@media only screen and (min-width: 40.063em) and (max-width: 64em) { 
  .content {
    
  }
  .nav {
    width: 100% !important;
    flex: 0 1 auto;
    max-width: none;
    height: 100px !important;
    overflow:hidden;
  }
  .nav.show {
    height: 450px !important;
  }
  .nav .menuBtn {
    display: block;
  }

  .flex-container {
    display: block;
  }
  .content {
    flex-direction: row;
    float: left !important;
    height: auto !important;
    width: 100% !important;
    flex: 0 1 auto;
  }

  .home {
    padding: 0 20px;
  }
  .mid-content,
  .mid-content.work {
    max-width: none;
  }
  .mid-content .thumb-container {
    position: relative;
    overflow: hidden;
    margin: 0 auto;
    height: 110vh;
  }
  .thumbs {
    margin: 0px auto; left: 0px; position: relative;
  }
  .main-content,
  .main-content.work {
    width: 68%;
    padding-right: 20px;
    overflow: visible;
  }
  .main-content.work .container {
    display: block;
  }
  .flex-main {
    width: 100%;
    max-width: none;
    float: left;
  }
  .flex-side {
    float: left;
    margin-bottom: 30px;
    clear: both;
    width: 100%;
  }
  .mid-content.toolkit,
  .main-content.about,
  .mid-content.work,
.main-content.work {
  flex-direction: column;
  float: none !important;
  height: auto !important;
}
.mid-content.toolkit,
.mid-content.work {
  width: auto !important;
  flex: 1;
}
.main-content.about,
.main-content.work {
  width: 100vh !important;
  flex: 3;
}
.controls {
  margin-top: 20px;
}
.scroll-btn,
.scroll-btn .MuiSvgIcon-root {
  height: 20px;
  width: 20px;
}
} /* min-width 641px and max-width 1024px, use when QAing tablet-only issues */


@media only screen and (min-width: 64.063em) { } /* min-width 1025px, large screens */

@media only screen and (min-width: 64.063em) and (max-width: 90em) { 
  .content {
    width: 75%;
  }
  .main-content.work {
    overflow-y: auto;
    width: 70%;
  }
  .controls {
    width: auto;
  }
  .scroll-btn,
  .scroll-btn .MuiSvgIcon-root {
    width: 30px;
    height: 30px;
  }
} /* min-width 1025px and max-width 1440px, use when QAing large screen-only issues */


@media only screen and (min-width: 90.063em) { } /* min-width 1441px, xlarge screens */

@media only screen and (min-width: 90.063em) and (max-width: 120em) { } /* min-width 1441px and max-width 1920px, use when QAing xlarge screen-only issues */


@media only screen and (min-width: 120.063em) { } /* min-width 1921px, xxlarge screens */